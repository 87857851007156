<template>
  <div class="container-fluid">
    <div class="row mobile flex-nowrap">
      <MobileSideBar id="mobile" />
      <SideBar id="desktop" />
      <div class="col bg-light">
        <h1>Muustuste logi</h1>
        <h6>10.07</h6>
        <p>Meeskonna litsentsi aktiveerimisel lisatud valikud:<br>valik 1 võtab kõik täisliikmed lisamise valikusse.<br>valik 2 võtab kõik liikmed täisliikmed, kellel on litsents väiksem kui meeskonna oma (näidatud oranzi värviga).<br>Valik 3 annab ette kõik liikmed ning on võimalik ise valida kes saab (siinkohal ei pea olema liige ka täisliige).</p>
        <p>Meeskonna vaates lisatud litsentsi kuupäev avavaates koos filtreerimise ning sorteerimisega</p>
        <p>SIM vaates kuupäeva valik parandatud: Vasakpoolne (alates) sisend lubab samat päeva vastusena. Parempoolne (kuni) ei luba järgmist päeva.</p>
        <p>SIM muutes lubatud 1oT operaatori valikuna</p>
        <p>Telefoninumbrid, mis algavad +380 lähevad kirja Ukraina telefoninumbrite nimekirja</p>
        <p></p>
        <h6>09.05</h6>
        <p>2000+ kasutajat ning arvu salvestatakse, et võimaldada kohest ligipääsu lehele. Ülejäänud kasutajad tõmbavad
        taustas ning valmisolekul tulevad olemasolevatele andmetele juurde. Filtritele/otsimistele tulevad andmed
        juurde andmete lisamisel. Iga leht, mis kasutab "users" andmeid näitab ka, kuna lehte viimati uuendati. Kõiki
        andmeid ei ole võimalik salvestada koheseks ligipääsuks, kuna andmete suurus ületab brauserites võimaldatud arvu.(5mb google chrome brauseris, andmeid 30mb+)</p>
        <p>Portaali logimine on lõpuks parandatud. Enam ei avane lisaleht portaali avamisel kasutaja lehelt. Lisaks on portaali auth juures nupp portaali lehele.</p>
        <p>Litsentside lisamine parandatud. Mitme litsentsi (10+) lisamine toimis edukalt nii meeskonna kui ka kasutajate lehel</p>
        <p>CSV allalaadimisel parandatud viga, mis ei võtnud search rea otsingu tulemused</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar.vue'
import MobileSideBar from '@/components/MobileSideBar.vue'

export default {
  name: 'news',
  components: {
    SideBar,
    MobileSideBar
  }
}
</script>
<style scoped>
#mobile{
    display: none
  }
@media screen and (max-width: 768px){
  #mobile{
    display: flex;
  }
  #desktop{
    display: none
  }
  .mobile{
    display: grid
  }
}
</style>