import { createStore } from 'vuex'
import axios from 'axios'
import { API_ENDPOINTS } from '@/config/api'
const UsersAPI = API_ENDPOINTS.TIW_API + "/users"

export default createStore({
  state: {
    users: [],
    usersLoaded: false
  },
  getters: {
    users: state => {
      return users.state
    },
    usersLoaded: state => {
      return usersLoaded.state
    }

  },
  mutations: {
    SET_Users (state, users) {
      state.users = users
    },
    SET_UsersLoaded (state, usersLoaded) {
      state.usersLoaded = usersLoaded
    },
    SET_LocalUsers (state, localUsers) {
      state.localUsers = localUsers
    }
  },
  actions: {
    loadUsers ({ commit }) {
      if(localStorage.length > 1){
        commit('SET_LocalUsers', true)
      }
      axios.get(UsersAPI).then(res => res.data).then(users => {
        for (let i = 0; i < users.length; i++){
          //min/max filters
          users[i].teamsinfo = []
          users[i].placesmin = users[i].places
          users[i].placesmax = users[i].places
          users[i].all_huntsmin = users[i].all_hunts
          users[i].all_huntsmax = users[i].all_hunts
          users[i].created_huntsmin = users[i].created_hunts
          users[i].created_huntsmax = users[i].created_hunts
          //Licenses
          users[i].licenseslength = 0
          users[i].last_license_date = ""
          if (users[i].licenses != null) {
            //Licenses amount
            let x = 0
            let length = users[i].licenses.length
            users[i].licenseslength = length
            users[i].licenseslengthmin = length
            users[i].licenseslengthmax = length
            //Last licence date
            for (let j = 0; j < length; j++) {
              if (users[i].licenses[j].validuntil > users[i].licenses[x].validuntil) x = j
            }
            let date = users[i].licenses[x].validuntil
            users[i].licenseslastdatemin = date
            users[i].licenseslastdatemax = date
            users[i].last_license = date
            let y = new Date(date).getFullYear()
            let m = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
            let d = ("0" + (new Date(date).getDate())).slice(-2)
            users[i].last_license_date = y+"-"+m+"-"+d
          }
          //Devices
          users[i].deviceslength = 0
          if(users[i].devices != null){
            length = users[i].devices.length
            users[i].deviceslength = length
            users[i].deviceslengthmin = length
            users[i].deviceslengthmax = length
          }
          //Created
          users[i].created_date = ""
          if (users[i].created != null) {
            let date = users[i].created
            users[i].createddatemin = date
            users[i].createddatemax = date
            let y = new Date(date).getFullYear()
            let m = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
            let d = ("0" + (new Date(date).getDate())).slice(-2)
            users[i].created_date = y+"-"+m+"-"+d
          }
          //Last joined
          users[i].last_joined_date = ""
          if (users[i].last_joined != null) {
            let date = users[i].last_joined
            users[i].last_joineddatemin = date
            users[i].last_joineddatemax = date
            let y = new Date(date).getFullYear()
            let m = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
            let d = ("0" + (new Date(date).getDate())).slice(-2)
            users[i].last_joined_date = y+"-"+m+"-"+d
          }
          //Notes
          users[i].noteslength = 0
          if (users[i].activity != null){
            for (let j = 0; j < users[i].activity.length; j++) {
              if (users[i].activity[j].name == "admin_note"){
                users[i].noteslength = users[i].activity[j].count
                break
              }
            }
            users[i].noteslengthmin = users[i].noteslength
            users[i].noteslengthmax = users[i].noteslength
          }
          //Country
          users[i].country = ""
          if (users[i].cellnumber != null){
            let nr = users[i].cellnumber
            if (nr.includes("+372")) users[i].country = "Estonia"
            else if (nr.includes("+371")) users[i].country = "Latvia"
            else if (nr.includes("+370")) users[i].country = "Lithuania"
            else if (nr.includes("+358")) users[i].country = "Finland"
            else if (nr.includes("+380")) users[i].country = "Ukraine"
            else if (nr.includes("+7")) users[i].country = "Russia"
            else if (nr.includes("+1")) users[i].country = "USA"
            else if (nr.includes("+49")) users[i].country = "Germany"
            else if (nr.includes("+353")) users[i].country = "Ireland"
          }
          //App and System
          users[i].app = ""
          users[i].phone = ""
          if (users[i].devices != null){
            let appver = users[i].devices[0].appversion.slice(-1)
            let opsys = users[i].devices[0].opsys
            if (appver == "1") users[i].app = "Huntloc"
            else if (appver == "2") users[i].app = "Liveteam"
            else if (appver == "3") users[i].app = "Doglo"
            if (opsys == "android") users[i].phone = "Android"
            if (opsys == "iPhone OS") users[i].phone = "iOS"
            if (opsys == "iOS") users[i].phone = "iOS"
          }
        }
        let tempusers = users
        users = []
        localStorage.clear()
        let j = 0
        for(let i = 0; tempusers.length > i ; i++){
          if(i > tempusers.length-2001 || tempusers[i].cellnumber == "+37256921625" || tempusers[i].cellnumber == "+3725132146" || tempusers[i].cellnumber == "+37259016773"){
            localStorage.setItem(2001-j, JSON.stringify(tempusers[i]))
            j++
          }
          users[i] = tempusers[tempusers.length-i-1]
        }
        let def = Date.now()
        let h = new Date(def).getHours()
        let min = ("0" + (new Date(def).getMinutes() + 1)).slice(-2)
        localStorage.setItem('last_updated', h+":"+min)
        commit('SET_UsersLoaded', true)
        commit('SET_Users', users)
        })
    }
  },
  modules: {
  }
})
