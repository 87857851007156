<template>
  <!--Adding new SIMs -->
  <Modal v-if="popupTriggers.addSIMTrigger" :TogglePopup="() => TogglePopup('addSIMTrigger')">
    <div>
      <h5>Add SIMS</h5>
      <p style="font-size:10px">Number; Valid Until (YYYY-MM-DD) ; Operator ; IMEI ; Brand ; Pricing Model ; Notes</p>
      <textarea id="textarea" style="width:400px; height:200px;"></textarea><br>
      <button class="btn btn-sm btn-outline-dark btn-rounded" @click="AddSIMs()" >Submit</button>
    </div>
  </Modal>

  <div class="col bg-light" style="width: 100%" v-if="loaded == false">
    <div class="lds-ring" style="margin-left: 50%; margin-top: 20%"><div></div><div></div><div></div><div></div></div>
  </div>
  <div v-else class="col bg-light">
    <!--Top area-->
    <div class="d-flex justify-content-between mt-3">
      <!--Picking amount of entries shown-->
      <div class="d-flex" style="font-size: 12px">
        <p class="pt-1">Entries:</p>
        <select v-model="currentEntries" class="form-control form-control-sm mb-3" style="font-weight: bold" @change="paginateEntries">
          <option v-for="se in showEntries" :key="se" :value="se">
            {{ se }}
          </option>
        </select>
      </div>

      <!--Search bar to search every column at once-->
      <div class="d-flex justify-content-between">
        <span></span>
        <span><button v-on:click="() => TogglePopup('addSIMTrigger')" class="mx-2 btn-sm btn-outline-dark btn-rounded btn">
          Add SIMS
        </button></span>
        <span><button @click="toggleValid()" class="mx-2 btn-sm btn-outline-dark btn-rounded btn">
            Valid SIMs:
            <small v-if="hidevalid == 'empty'">No</small>
            <small v-else-if="hidevalid == 'full'">Yes</small>
            <small v-else-if="hidevalid == ''">All</small>
          </button></span>
        <span><button @click="toggleDeleted()" class="mx-2 btn-sm btn-outline-dark btn-rounded btn">
            Deleted SIMs:
            <small v-if="hidedeleted == 'empty'">No</small>
            <small v-else-if="hidedeleted == 'full'">Yes</small>
            <small v-else-if="hidedeleted == ''">All</small>
          </button></span
        >
        <span
          ><button
            @click="downloadCSV()"
            class="mx-2 btn btn-sm btn-outline-dark btn-rounded"
          >
            Download CSV
          </button></span
        >
        <span
          ><button
            class="btn-sm btn btn-outline-dark btn-rounded mx-2"
            @click="resetSims"
          >
            Reset filters
          </button></span
        >
        <span
          ><input
            class="search form-control form-control-sm me3 pt-2"
            type="text"
            placeholder="Search.."
            v-model="searchInput"
            @keyup="searchEvent"
        /></span>
      </div>
    </div>

    <!--SIMs Tables-->
    <table class="table table-bordered table-hover">
      <!--Rows for titles and filters-->
      <thead>
        <!--Table row for column titles-->
        <tr class="h6">
          <!--Contents that are sorted-->
          <th>
            <div class="d-flex justify-content-between">
              <span>Number</span>
              <span
                v-if="sortnumber == 'default'"
                @click.prevent="sortByColumn('phoneNumber')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortnumber == 'desc'"
                @click.prevent="sortByColumn('phoneNumber')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortnumber == 'asc'"
                @click.prevent="sortByColumn('phoneNumber')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Valid Until</span>
              <span
                v-if="sortvalid == 'default'"
                @click.prevent="sortByColumn('validUntil')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortvalid == 'desc'"
                @click.prevent="sortByColumn('validUntil')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortvalid == 'asc'"
                @click.prevent="sortByColumn('validUntil')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Deleted</span>
              <span
                v-if="sortdeleted == 'default'"
                @click.prevent="sortByColumn('deleted')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortdeleted == 'desc'"
                @click.prevent="sortByColumn('deleted')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortdeleted == 'asc'"
                @click.prevent="sortByColumn('deleted')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <!--Rest of the content-->
          <th v-for="tc in tableColumns" :key="tc">
            <div>{{ tc.text }}</div>
          </th>
          <th>Actions</th>
        </tr>
        <!--Table row for column filters-->
        <tr>
          <th>
            <input
              type="search"
              class="form-control search"
              placeholder=""
              aria-label="Number"
              v-model="col.phoneNumber"
              @keyup="filterByColumn"
            />
          </th>

          <th>
            <div style="display: flex">
              <input
                type="search"
                class="form-control form-control-sm"
                v-model="col.validmin"
                @keyup="filterByColumn"
              />-<input
                type="search"
                class="form-control form-control-sm"
                v-model="col.validmax"
                @keyup="filterByColumn"
              />
            </div>
          </th>

          <th>
            <div style="display: flex">
              <input
                type="search"
                class="form-control form-control-sm"
                v-model="col.deletedmin"
                @keyup="filterByColumn"
              />-<input
                type="search"
                class="form-control form-control-sm"
                v-model="col.deletedmax"
                @keyup="filterByColumn"
              />
            </div>
          </th>

          <th>
            <div v-if="col.status.length == 0">
              <select
                class="form-select"
                v-model="col.status"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Active">Active</option>
                <option value="New">New</option>
                <option value="Stopped">Stopped</option>
                <option value="Closed">Closed</option>
              </select>
            </div>

            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.status"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Active">Active</option>
                <option value="New">New</option>
                <option value="Stopped">Stopped</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
          </th>

          <th>
            <div v-if="col.operator.length == 0">
              <select
                class="form-select"
                v-model="col.operator"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Telia">Telia</option>
                <option value="TeliaSWE">TeliaSWE</option>
                <option value="TravelSIM">TravelSIM</option>
                <option value="1oT">1oT</option>
              </select>
            </div>

            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.operator"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Telia">Telia</option>
                <option value="TeliaSWE">TeliaSWE</option>
                <option value="TravelSIM">TravelSIM</option>
                <option value="1oT">1oT</option>
              </select>
            </div>
          </th>

          <th>
            <div class="d-flex justify-content-between">
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.imei"
                  @keyup="filterByColumn"
              /></span>

              <div v-if="col.imei.length == 0">
                <span
                  ><select
                    class="form-select"
                    v-model="col.imei"
                    @change="filterByColumn"
                  >
                    <option value="">Default</option>
                    <option value="     ">Empty</option>
                  </select>
                </span>
              </div>

              <div v-else class="d-flex justify-content-between">
                <span
                  ><select
                    class="form-select form-search"
                    v-model="col.imei"
                    @change="filterByColumn"
                  >
                    <option value="">Default</option>
                    <option value="     ">Empty</option>
                  </select>
                </span>
              </div>
            </div>
          </th>

          <th>
            <div v-if="col.brand.length == 0">
              <select
                class="form-select"
                v-model="col.brand"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Huntloc">Huntloc</option>
                <option value="Doglo">Doglo</option>
                <option value="Valve">Valve</option>
              </select>
            </div>

            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.brand"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Huntloc">Huntloc</option>
                <option value="Doglo">Doglo</option>
                <option value="Valve">Valve</option>
              </select>
            </div>
          </th>

          <th>
            <div v-if="col.pricingModel.length == 0">
              <select
                class="form-select"
                v-model="col.pricingModel"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Regular">Regular</option>
                <option value="Full service">Full service</option>
              </select>
            </div>

            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.pricingModel"
                @change="filterByColumn"
              >
                <option value=""></option>
                <option value="     ">Empty</option>
                <option value="Regular">Regular</option>
                <option value="Full service">Full service</option>
              </select>
            </div>
          </th>

          <th>
            <input
              type="search"
              class="form-control search"
              placeholder=""
              v-model="col.notes"
              @keyup="filterByColumn"
            />
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="td in tableData" :key="td">
          <td>
            <div v-if="tagEditingId == td.id">
              <input
                id="phoneNumber"
                type="text"
                class="form-control"
                v-model="td.phoneNumber"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              />
            </div>
            <div v-else @click="setTagEditingId(td.id)">
              {{ td.phoneNumber }}
            </div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <input
                id="validUntil"
                class="form-control"
                type="date"
                v-model="td.validUntil"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              />
            </div>
            <div v-else @click="setTagEditingId(td.id)">
              {{ td.validUntil }}
            </div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <input
                id="deleted"
                class="form-control"
                type="date"
                v-model="td.deleted"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              />
            </div>
            <div v-else @click="setTagEditingId(td.id)">{{ td.deleted }}</div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <select
                class="form-select"
                id="status"
                v-model="td.status"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              >
                <option value="Active">Active</option>
                <option value="New">New</option>
                <option value="Stopped">Stopped</option>
                <option value="Closed">Closed</option>
              </select>
            </div>

            <div v-else @click="setTagEditingId(td.id)">
              <div v-if="td.status == 'Stopped'">
                <div v-bind:class="{ stopped: true }">{{ td.status }}</div>
              </div>
              <div v-else-if="td.status == 'Closed'">
                <div v-bind:class="{ closed: true }">{{ td.status }}</div>
              </div>
              <div v-else>{{ td.status }}</div>
            </div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <select
                class="form-select"
                id="operator"
                v-model="td.operator"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              >
                <option value="Telia">Telia</option>
                <option value="TeliaSWE">TeliaSWE</option>
                <option value="TravelSIM">TravelSIM</option>
                <option value="1oT">1oT</option>
              </select>
            </div>
            <div v-else @click="setTagEditingId(td.id)">{{ td.operator }}</div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <input
                id="imei"
                type="text"
                class="form-control"
                v-model="td.imei"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              />
            </div>
            <div v-else @click="setTagEditingId(td.id)">{{ td.imei }}</div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <select
                class="form-select"
                id="brand"
                v-model="td.brand"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              >
                <option value="Huntloc">Huntloc</option>
                <option value="Doglo">Doglo</option>
                <option value="Valve">Valve</option>
              </select>
            </div>
            <div v-else @click="setTagEditingId(td.id)">{{ td.brand }}</div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <select
                class="form-select"
                id="pricingModel"
                v-model="td.pricingModel"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              >
                <option value="Regular">Regular</option>
                <option value="Full service">Full service</option>
              </select>
            </div>
            <div v-else @click="setTagEditingId(td.id)">
              {{ td.pricingModel }}
            </div>
          </td>
          <td>
            <div v-if="tagEditingId == td.id">
              <input
                id="notes"
                type="text"
                class="form-control"
                v-model="td.notes"
                :focusId="'tag-edit-${id}'"
                @keydown.enter="updateSimNumber(td)"
                @keydown.escape="resetEditing()"
              />
            </div>
            <div v-else @click="setTagEditingId(td.id)">{{ td.notes }}</div>
          </td>
          <td>
            <div
              v-if="tagEditingId == td.id"
              class="d-flex justify-content-around"
            >
              <span
                ><button
                  class="mx-1 btn-outline-dark btn-rounded btn rounded-pill"
                  @click="updateSimNumber(td)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="11"
                    fill="currentColor"
                    class="bi bi-plus-lg"
                    viewBox="0 0 16 20"
                  >
                    <path
                      d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"
                    />
                  </svg></button
              ></span>
              <span
                ><button
                  class="btn-outline-dark btn-rounded btn rounded-pill"
                  @click="resetEditing()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="11"
                    fill="currentColor"
                    class="bi bi-dash-lg"
                    viewBox="0 0 16 20"
                  >
                    <path
                      d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"
                    />
                  </svg></button
              ></span>
            </div>
            <div v-else class="d-flex justify-content-around">
              <span
                ><button
                  class="mx-1 btn-outline-dark btn-rounded btn rounded-pill"
                  @click="setTagEditingId(td.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="11"
                    fill="currentColor"
                    class="bi bi-pen"
                    viewBox="0 0 16 20"
                  >
                    <path
                      d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"
                    />
                  </svg></button
              ></span>
              <span
                ><button
                  class="btn-outline-dark btn-rounded btn rounded-pill"
                  @click="removeSIM(td.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="11"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 20"
                  >
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg></button
              ></span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex d-flex justify-content-between mb-1">
      <div style="font-size: 12px">
        Show {{ showInfo.from }} to {{ showInfo.to }} of
        {{ showInfo.of }} entries
      </div>
      <div style="font-size: 10px">
        <ul class="list-group list-group-horizontal mb-1">
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === 1 },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="(currentPage = 1), paginateEntries()"
              >First</a
            >
          </li>
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === 1 },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="
                currentPage < 1 ? (currentPage = 1) : (currentPage -= 1),
                  paginateEntries()
              "
              >Prev</a
            >
          </li>
          <li
            v-for="pagi in showPagination"
            :key="pagi"
            :class="[
              'list-group-item border px-2',
              { ellipsis: pagi === '...', active: pagi === currentPage },
            ]"
          >
            <a
              href="#"
              class="nav-link bg-white text-dark"
              @click.prevent="paginateEvent(pagi)"
              >{{ pagi }}</a
            >
          </li>
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === allPages },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="
                currentPage > allPages
                  ? (currentPage = allPages)
                  : (currentPage += 1),
                  paginateEntries()
              "
              >Next</a
            >
          </li>
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === allPages },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="(currentPage = allPages), paginateEntries()"
              >Last</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import * as $ from "alga-js";
import axios from "axios";
import Modal from "@/components/Modal.vue";
import { API_ENDPOINTS } from '@/config/api'

export default {
  name: "SimsTable",
  components: {
    Modal,
  },
  setup() {
    const popupTriggers = ref({ addSIMTrigger: false })
    const TogglePopup = (trigger) => { popupTriggers.value[trigger] = !popupTriggers.value[trigger]}
    const store = inject('store')
    return {
      Modal,
      popupTriggers,
      TogglePopup,
      store
    }
  },
  mounted() {
    this.$store.dispatch('loadSims')
  },
  data() {
    return {
      hidevalid: "",
      hidedeleted: "",
      sortnumber: "default",
      sortvalid: "default",
      sortdeleted: "default",
      stopped: false,
      closed: false,
      validSort: "",
      deletedSort: "",
      tagEditingId: "",
      loaded: false,
      csvData: [],
      columns: [
        { name: "status", text: "Status" },
        { name: "operator", text: "Operator" },
        { name: "imei", text: "IMEI" },
        { name: "brand", text: "Brand" },
        { name: "pricingModel", text: "Pricing Model" },
        { name: "notes", text: "Notes" },
      ],
      entries: [],
      showEntries: [1, 5, 10, 15, 25, 50, 100, 250, 500, 1000, 1500],
      currentEntries: 50,
      filteredEntries: [],
      currentPage: 1,
      allPages: 1,
      searchInput: "",
      searchEntries: [],
      col: {
        phoneNumber: "",
        validUntil: "",
        deleted: "",
        status: "",
        operator: "",
        imei: "",
        brand: "",
        pricingModel: "",
        notes: "",
      },
      sortcol: {
        phoneNumber: "",
        validUntil: "",
        deleted: "",
        status: "",
        operator: "",
        imei: "",
        brand: "",
        pricingModel: "",
        notes: "",
      },
    };
  },
  computed: {
    showInfo() {
      return $.array.pageInfo(
        this.getCurrentEntries(),
        this.currentPage,
        this.currentEntries
      );
    },
    showPagination() {
      return $.array.pagination(this.allPages, this.currentPage, 3);
    },
    tableColumns() {
      return this.columns;
    },
    tableData() {
      return this.filteredEntries;
    },
  },
  created() {
    this.getAllSims()
  },
  methods: {
    //Adding SIM to pgapi
    AddSIMs() {
      let text = document.getElementById("textarea")
      let lines = text.value.split("\n")
      let alertNumbers = ""
      for (let i = 0; i < lines.length; i++){
        if(lines[i].length > 1){
          let split = lines[i].split(";")
          let sim = {
            phoneNumber: split[0],
            validUntil: split[1],
            status: 'New',
            operator: split[2],
            imei: split[3],
            brand: split[4],
            pricingModel: split[5],
            notes: split[6]
          }
          let API = API_ENDPOINTS.TIW_API + "/sim"
          if (i+1 == lines.length) alertNumbers += sim.phoneNumber + "."
          else alertNumbers += sim.phoneNumber + ", "
          axios.post(API, sim).then(res => {
            this.entries.push(sim)
          })
        }  
      }
      alert("SIM added: "+alertNumbers)
      this.TogglePopup('addSIMTrigger')
      this.paginateData(this.entries);
      this.allPages = $.array.pages(this.entries, this.currentEntries);
    },

    async getAllSims() {
      let backendAPI = API_ENDPOINTS.TIW_API + "/sim/all";
      axios.get(backendAPI).then((res) => {
        this.entries = res.data;
        this.defaultentries = res.data;
        this.tempentries = res.data;
        this.filterentries = res.data;
        this.deletednull = [];
        this.validnull = [];
        this.deletednotnull = [];
        this.validnotnull = [];
        this.defaultvalidnull = [];
        this.defaultvalidnotnull = [];
        this.defaultdeletednull = [];
        this.defaultdeletednotnull = [];
        for (let i = 0; i < this.entries.length; i++) {
          if (this.entries[i].deleted == null) {
            this.deletednull.push(this.entries[i]);
            this.defaultdeletednull.push(this.entries[i]);
          }
          if (this.entries[i].deleted != null) {
            let things = this.entries[i].deleted.split("-");
            let yyyy = things[0];
            let mm = things[1];
            let dd = things[2];
            let date = new Date(mm + "/" + dd + "/" + yyyy + " 00:00:00");
            let ms = date.getTime();
            this.entries[i].deletedmax = ms;
            this.entries[i].deletedmin = ms;
            this.deletednotnull.push(this.entries[i]);
            this.defaultdeletednotnull.push(this.entries[i]);
          }
          if (this.entries[i].validUntil == null) {
            this.validnull.push(this.entries[i]);
            this.defaultvalidnull.push(this.entries[i]);
          }
          if (this.entries[i].validUntil != null) {
            let things = this.entries[i].validUntil.split("-");
            let yyyy = things[0];
            let mm = things[1];
            let dd = things[2];
            let date = new Date(mm + "/" + dd + "/" + yyyy + " 23:59:59");
            let ms = date.getTime();
            this.entries[i].validmax = ms;
            this.entries[i].validmin = ms;
            this.validnotnull.push(this.entries[i]);
            this.defaultvalidnotnull.push(this.entries[i]);
          }
          if (this.entries[i].status == null) {
            this.entries[i].status = "    ";
          }
          if (this.entries[i].operator == null) {
            this.entries[i].operator = "    ";
          }
          if (this.entries[i].imei == null) {
            this.entries[i].imei = "    ";
          }
          if (this.entries[i].brand == null) {
            this.entries[i].brand = "    ";
          }
          if (this.entries[i].pricingModel == null) {
            this.entries[i].pricingModel = "    ";
          }
        }
        console.log(this.entries);
        this.csvData = this.entries
        this.loaded = true;
        this.paginateData(this.entries);
        this.allPages = $.array.pages(this.entries, this.currentEntries);
      });
    },

    //Download Data as CSV from filtered entries
    downloadCSV() {
      const rows = []
      const data = this.csvData
      const headers = ["id", "phoneNumber", "validUntil", "deleted", "status", "operator", "imei", "brand", "pricingModel", "notes"]
      rows.push(headers.join(";"))

      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"')
          if (`"${escaped}"` == '"null"') return '""'
          else return `"${escaped}"`
        })
        rows.push(values.join(";"))
      }
      let csv = rows.join("\n")

      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.setAttribute("hidden", "")
      a.setAttribute("href", url)
      a.setAttribute("download", "Sims.csv")
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },

    resetValid() {
      this.validnull = [];
      for (let i = 0; i < this.defaultvalidnull.length; i++) {
        this.validnull.push(this.defaultvalidnull[i]);
      }
      this.validnotnull = [];
      for (let i = 0; i < this.defaultvalidnotnull.length; i++) {
        this.validnotnull.push(this.defaultvalidnotnull[i]);
      }
    },
    resetDeleted() {
      this.deletednull = [];
      for (let i = 0; i < this.defaultdeletednull.length; i++) {
        this.deletednull.push(this.defaultdeletednull[i]);
      }
      this.deletednotnull = [];
      for (let i = 0; i < this.defaultdeletednotnull.length; i++) {
        this.deletednotnull.push(this.defaultdeletednotnull[i]);
      }
    },
    toggleDeleted() {
      //Toggle
      if (this.hidedeleted == "") {
        this.hidedeleted = "empty";
      } else if (this.hidedeleted == "empty") {
        this.hidedeleted = "full";
      } else if (this.hidedeleted == "full") {
        this.hidedeleted = "";
      }
      this.validnull = [];
      this.validnotnull = [];
      //Entry change
      if (this.hidedeleted == "empty") {
        //entries are values with deleted == null
        this.entries = this.deletednull;
        this.tempentries = this.deletednull;
        this.filterentries = this.deletednull;

        for (let i = 0; i < this.entries.length; i++) {
          //valid null deleted null
          for (let j = 0; j < this.defaultvalidnull.length; j++) {
            if (this.entries[i].id == this.defaultvalidnull[j].id) {
              this.validnull.push(this.defaultvalidnull[j]);
            }
          }

          //valid !null deleted null
          for (let j = 0; j < this.defaultvalidnotnull.length; j++) {
            if (this.entries[i].id == this.defaultvalidnotnull[j].id) {
              this.validnotnull.push(this.defaultvalidnotnull[j]);
            }
          }
        }
      } else if (this.hidedeleted == "full") {
        this.entries = this.deletednotnull;
        this.tempentries = this.deletednotnull;
        this.filterentries = this.deletednotnull;

        for (let i = 0; i < this.entries.length; i++) {
          //valid null deleted !null
          for (let j = 0; j < this.defaultvalidnull.length; j++) {
            if (this.entries[i].id == this.defaultvalidnull[j].id) {
              this.validnull.push(this.defaultvalidnull[j]);
            }
          }

          //valid !null deleted !null
          for (let j = 0; j < this.defaultvalidnotnull.length; j++) {
            if (this.entries[i].id == this.defaultvalidnotnull[j].id) {
              this.validnotnull.push(this.defaultvalidnotnull[j]);
            }
          }
        }
      } else if (this.hidedeleted == "") {
        this.resetValid();
        if (this.hidevalid == "") {
          this.entries = this.defaultentries;
          this.tempentries = this.defaultentries;
          this.filterentries = this.defaultentries;
        } else if (this.hidevalid == "empty") {
          this.entries = this.defaultvalidnull;
          this.tempentries = this.defaultvalidnull;
          this.filterentries = this.defaultvalidnull;
        } else if (this.hidevalid == "full") {
          this.entries = this.defaultvalidnotnull;
          this.tempentries = this.defaultvalidnotnull;
          this.filterentries = this.defaultvalidnotnull;
        }
      }
      console.log("Validnull");
      console.log(this.validnull);
      console.log("Validnotnull");
      console.log(this.validnotnull);
      this.paginateData(this.entries);
      this.allPages = $.array.pages(this.entries, this.currentEntries);
    },
    toggleValid() {
      //Toggle
      if (this.hidevalid == "") {
        this.hidevalid = "empty";
      } else if (this.hidevalid == "empty") {
        this.hidevalid = "full";
      } else if (this.hidevalid == "full") {
        this.hidevalid = "";
      }
      this.deletednull = [];
      this.deletednotnull = [];
      //Entry change
      if (this.hidevalid == "empty") {
        //entries are values with deleted == null
        this.entries = this.validnull;
        this.tempentries = this.validnull;
        this.filterentries = this.validnull;

        for (let i = 0; i < this.entries.length; i++) {
          //deleted null valid null
          for (let j = 0; j < this.defaultdeletednull.length; j++) {
            if (this.entries[i].id == this.defaultdeletednull[j].id) {
              this.deletednull.push(this.defaultdeletednull[j]);
            }
          }

          //deleted !null valid null
          for (let j = 0; j < this.defaultdeletednotnull.length; j++) {
            if (this.entries[i].id == this.defaultdeletednotnull[j].id) {
              this.deletednotnull.push(this.defaultdeletednotnull[j]);
            }
          }
        }
      } else if (this.hidevalid == "full") {
        this.entries = this.validnotnull;
        this.tempentries = this.validnotnull;
        this.filterentries = this.validnotnull;

        for (let i = 0; i < this.entries.length; i++) {
          //deleted null valid !null
          for (let j = 0; j < this.defaultdeletednull.length; j++) {
            if (this.entries[i].id == this.defaultdeletednull[j].id) {
              this.deletednull.push(this.defaultdeletednull[j]);
            }
          }

          //deleted !null valid !null
          for (let j = 0; j < this.defaultdeletednotnull.length; j++) {
            if (this.entries[i].id == this.defaultdeletednotnull[j].id) {
              this.deletednotnull.push(this.defaultdeletednotnull[j]);
            }
          }
        }
      } else if (this.hidevalid == "") {
        this.resetDeleted();
        if (this.hidedeleted == "") {
          this.entries = this.defaultentries;
          this.tempentries = this.defaultentries;
          this.filterentries = this.defaultentries;
        } else if (this.hidedeleted == "empty") {
          this.entries = this.defaultdeletednull;
          this.tempentries = this.defaultdeletednull;
          this.filterentries = this.defaultdeletednull;
        } else if (this.hidedeleted == "full") {
          this.entries = this.defaultdeletednotnull;
          this.tempentries = this.defaultdeletednotnull;
          this.filterentries = this.defaultdeletednotnull;
        }
      }
      console.log("Deletednull");
      console.log(this.deletednull);
      console.log("Deletednotnull");
      console.log(this.deletednotnull);
      this.paginateData(this.entries);
      this.allPages = $.array.pages(this.entries, this.currentEntries);
    },

    resetEditing() {
      this.tagEditingId = "";
    },
    updateSimNumber(sim) {
      this.tagEditingId = "";
      this.updateSim(sim);
    },
    setTagEditingId(id) {
      this.tagEditingId = id;
      console.log(id);
      setTimeout(() => {
        // document.getElementById('tag-edit-${id}').focus()
      }, 1);
    },
    updateSim(updatedSim) {
      let backendAPI = API_ENDPOINTS.TIW_API + "/sim/";
      axios
        .put(backendAPI, updatedSim)
        .then((res) => {
          //Perform Success Action
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //action that happens regardless of success/fail
        });
    },
    removeSIM(id) {
      let answer = confirm("Do you want to delete this SIM?");
      let backendAPI = API_ENDPOINTS.TIW_API + "/sim/" + id;
      if (answer) {
        axios
          .delete(backendAPI, this.sim)
          .then((res) => {
            //Perform Success Action
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            //action that happens regardless of success/fail
          });
        alert("Sim has been deleted");
      } else {
        console.log("Sim has not been deleted");
      }
    },

    resetSims() {
      (this.hidedeleted = ""),
        (this.hidevalid = ""),
        (this.sortnumber = "default"),
        (this.sortvalid = "default"),
        (this.sortdeleted = "default"),
        (this.entries = this.defaultentries);
      this.currentPage = 1;
      this.searchInput = "";
      this.resetEditing();
      (this.col = {
        phoneNumber: "",
        validUntil: "",
        deleted: "",
        status: "",
        operator: "",
        imei: "",
        brand: "",
        pricingModel: "",
        notes: "",
      }),
        this.paginateEntries();
    },
    paginateEntries() {
      if (this.searchInput.length >= 1) {
        this.searchEntries = $.array.search(this.entries, this.searchInput);
        this.paginateData(this.searchEntries);
      } else {
        this.searchEntries = [];
        this.paginateData(this.entries);
      }
      this.csvData = this.filteredEntries
    },
    paginateEvent(page) {
      this.currentPage = page;
      this.paginateEntries();
    },
    searchEvent() {
      (this.sortnumber = "default"),
        (this.sortvalid = "default"),
        (this.sortdeleted = "default"),
        (this.currentPage = 1);
      this.paginateEntries();
    },
    paginateData(data) {
      this.filteredEntries = $.array.paginate(
        data,
        this.currentPage,
        this.currentEntries
      );
      this.allPages = $.array.pages(data, this.currentEntries);
    },
    getCurrentEntries() {
      return this.searchEntries.length <= 0 ? this.entries : this.searchEntries;
    },
    filterByColumn() {
      this.resetSortIcon();
      this.currentPage = 1;
      this.entries = this.tempentries;
      const filterCol = $.object.removeBy(this.col, "");
      let filterData = this.getCurrentEntries();
      if (Object.entries(filterCol).length >= 1) {
        filterData = this.filter(filterCol);
      }
      this.entries = filterData;
      this.filterentries = this.entries;
      this.resetSortLogic();
      this.paginateData(this.entries);
      this.allPages = $.array.pages(this.entries, this.currentEntries);
    },
    resetSortIcon() {
      (this.sortnumber = "default"),
        (this.sortdeleted = "default"),
        (this.sortvalid = "default");
    },
    resetSortLogic() {
      this.sortcol["phoneNumber"] = "";
      this.sortcol["validUntil"] = "";
      this.sortcol["deleted"] = "";
    },
    sortByColumn(column) {
      let sortedEntries = this.getCurrentEntries();
      let sortedColumn = this.sortcol[column];
      if (sortedColumn === "") {
        this.sortcol[column] = "asc";
        if (column == "phoneNumber") {
          this.sortnumber = "asc";
        } else if (column == "validUntil") {
          this.sortvalid = "asc";
        } else if (column == "deleted") {
          this.sortdeleted = "asc";
        }
        sortedEntries = $.array.sortBy(this.getCurrentEntries(), column, "asc");
        this.entries = sortedEntries;
      } else if (sortedColumn === "asc") {
        this.sortcol[column] = "desc";
        if (column == "phoneNumber") {
          this.sortnumber = "desc";
        } else if (column == "validUntil") {
          this.sortvalid = "desc";
        } else if (column == "deleted") {
          this.sortdeleted = "desc";
        }
        sortedEntries = $.array.sortBy(
          this.getCurrentEntries(),
          column,
          "desc"
        );
        this.entries = sortedEntries;
      } else if (sortedColumn === "desc") {
        this.sortcol[column] = "";
        if (column == "phoneNumber") {
          this.sortnumber = "default";
        } else if (column == "validUntil") {
          this.sortvalid = "default";
        } else if (column == "deleted") {
          this.sortdeleted = "default";
        }
        this.entries = this.filterentries;
      }
      this.paginateData(this.entries);
    },
    filter(filterObj) {
      let filteredArray = Array.from(this.getCurrentEntries());
      for (let [key, val] of Object.entries(filterObj, "", 0)) {
        const filterfromArr = filteredArray.filter((obj) => {
          if (key in obj) {
            //filtering strings
            if (!key.includes("min") && !key.includes("max")) {
              if (obj[key] == Number(val)) return true;
              else if (obj[key] && obj[key].toLowerCase().includes(val.toLowerCase())) return true;
            }
            if (key.includes("min") || key.includes("max")) {
              //dates
              if (val.length >= 4) {
                let date, yyyy, valms, value;
                if (val.includes("-")) {
                  valms = val.split("-");
                  yyyy = valms[0];
                  if (valms[1] != "" && valms[1] != null) {
                    let mm;
                    if (valms[1].length < 2) mm = "0" + valms[1];
                    else mm = valms[1];
                    if (valms[2] != "" && valms[2] != null) {
                      let dd;
                      if (valms[2].length < 2) dd = "0" + valms[2];
                      else dd = valms[2];
                      date = new Date([yyyy, mm, dd].join("-"));
                    } else {
                      date = new Date([yyyy, mm].join("-"));
                    }
                  } else {
                    date = new Date(yyyy);
                  }
                } else {
                  yyyy = val;
                  date = new Date(yyyy);
                }
                let object = Number(obj[key]);
                if (key.includes("min")) {
                  value = date.getTime();
                  if (object >= value) {
                    return true;
                  }
                } else if (key.includes("max")) {
                  value = date.getTime() + 86400000;
                  if (object <= value) {
                    return true;
                  }
                }
              }
            }
          }
          return false;
        });
        filteredArray = filterfromArr;
      }
      this.csvData = filteredArray
      return filteredArray;
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: unset !important;
}
th,
td,
tr,
select,
input,
body {
  font-size: 10px;
}
button {
  font-size: 10px;
}
.nav-link {
  font-size: 8px;
}
.stopped {
  background-color: yellow;
}
.closed {
  background-color: salmon;
}
.bi:hover {
  color: darkblue;
  cursor: pointer;
}
.search {
  font-weight: bold;
  color: orangered;
}
.form-search {
  background-color: salmon;
  font-weight: bold;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
